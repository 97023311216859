import React, { Component } from 'react';

const Resume = ( {resumeData} ) => {

/*     let resumeData = this.props.resumeData; */
    return (
      <section id="resume">

         <div className="row education">

            <div className="two columns header-col">
               <h1><span>Education</span></h1>
            </div>

            <div className="four columns main-col">
              {
                resumeData.education && resumeData.education.map((item)=>{
                  return(
                    <div className="row item">
                       <div className="twelve columns">
                          <h3>{item.UniversityName}</h3>
                          <p className="info">
                          {item.specialization}
                          {/* <span>&bull;</span>  */}
                          <br/>
                          <em className="date">{item.StartMonth} {item.StartYear} – {item.MonthOfPassing} {item.YearOfPassing}</em></p>
                          <p>
                          {item.Achievements}
                          </p>
                       </div>
                    </div>
                  )
                })
              }
            </div>
{/*          </div>
        <div className="row work"> */}
            <div className="two columns header-col">
               <h1><span>Work</span></h1>
            </div>

            <div className="four columns main-col">
              {
                resumeData.work && resumeData.work.map((item) => {
                  return(
                    <div className="row item">
                       <div className="twelve columns">
                          <h3>{item.CompanyName}</h3>
                          <p className="info">
                          {item.specialization}
                          {/* <span>&bull;</span>  */}
                          <br/>
                          <em className="date">{item.StartMonth} {item.StartYear} – {item.MonthOfLeaving} {item.YearOfLeaving}</em></p>
                          <p>
                          {item.Achievements}
                          </p>
                       </div>

                    </div>

                  )
                })
              }
            </div> 
         </div>


         <div className="row skill">

            <div className="two columns header-col">
               <h1><span className="programmingShift">Programming</span></h1>
            </div>

            <div className="four columns main-col">

{/*                <p>
               {resumeData.skillsDescription}
               &nbsp;
               </p> */}

   				<div className="bars">

   				   <ul className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item) => {
                    return(
                      <li>
                      <span className={`bar-expand ${item.skillname.toLowerCase().replace(/ /g, "")}`}>
                      </span><em>{item.skillname}</em>
                      </li>
                    )
                  })
                }

   					</ul>

   				</div>

   			</div>

         <div className="two columns header-col">
               <h1><span>Software</span></h1>
            </div>

            <div className="four columns main-col">

{/*                <p>
               {resumeData.skillsDescription}
               &nbsp;
               </p>
 */}
   				<div className="bars">

   				   <ul className="skills">
                {
                  resumeData.software && resumeData.software.map((item) => {
                    return(
                      <li>
                      <span className={`bar-expand ${item.softwarename.toLowerCase().replace(/ /g, "")}`}>
                      </span><em>{item.softwarename}</em>
                      </li>
                    )
                  })
                }

   					</ul>

   				</div>

   			</div>

         </div>

      </section>
    ); 
}

export default Resume;