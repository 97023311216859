let resumeData = {
    "imagebaseurl":"https://www.robertdantoni.dev/",
    "name": "Robert D'Antoni",
    "role": "Frontend developer with a past life in the video world.",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "The work I create merges a programming mind with years of visual aesthetics.",
    "blockchain" :"Blockchain enthusiaste",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/robert-d-antoni-14577813/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/Robert-DANT/",
          "className":"fa fa-github"
        },
/*         {
          "name":"skype",
          "url":"http://twitter.com/",
          "className":"fa fa-twitter"
        } */
      ],
    "aboutme":"My name is Robert D’Antoni and I’m a Frontend Developer based in Hamburg, Germany. Originally I worked as a motion graphics artist and video editor for more than 15 years before moving into the programming field.",
    "address":"Hamburg, Germany",
    "website":"robertdantoni.dev",
    "email":"robdantoni@gmail.com",
    "education":[
      {
        "UniversityName":"WBS Coding School",
        "specialization":"Full-Stack Web & App Development",
        "StartMonth":"March",
        "StartYear":"2021",
        "MonthOfPassing":"July",
        "YearOfPassing":"2021",
/*         "Achievements":"Some Achievements" */
      },
      {
        "UniversityName":"School of Visual Arts",
        "specialization":"Motion Graphics",
        "StartMonth":"September",
        "StartYear":"1999",
        "MonthOfPassing":"May",
        "YearOfPassing":"2003",
/*         "Achievements":"Some Achievements" */
      }
    ],
    "work":[
      {
        "CompanyName":"Freelancer",
        "specialization":"Video Editing & Motion Graphic Designer",
        "StartMonth":"May",
        "StartYear":"2020",
        "MonthOfLeaving":"Present",
        "YearOfLeaving":"",
      },
      {
        "CompanyName":"Maground GmbH",
        "specialization":"Video Editor & Motion Graphic Designer",
        "StartMonth":"January",
        "StartYear":"2017",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2020",
/*         "Achievements":"Some Achievements" */
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Bootstrap"
      },
      {
        "skillname":"Javascript"
      },
      {
        "skillname":"React"
      },
      {
        "skillname":"Angular"
      },
      {
        "skillname":"Node"
      },
      {
        "skillname":"Express"
      },
      {
        "skillname":"MongoDB"
      },
    ],
    "software":[
      {
        "softwarename":"Trello"
      },
      {
        "softwarename":"Miro"
      },
      {
        "softwarename":"GitHub"
      },
      {
        "softwarename":"Photoshop"
      },
      {
        "softwarename":"Illustrator"
      },
      {
        "softwarename":"InDesign"
      },
      {
        "softwarename":"Premiere Pro"
      },
      {
        "softwarename":"After Effects"
      },
      {
        "softwarename":"Windows and Mac"
      },
    ],
    "portfolio":[
      {
        "name":"Komunikate",
        "url":"https://www.komunikate.live/",
        "description":"A Web Application developed as a proof of concept to connect and inform people moving to Germany.",
        "imgurl":"images/portfolio/Komunikate_Thumb_C.jpg",
        "overtext":"Click to Komunikate"
      },
      {
        "name":"Pokefight",
        "url":"https://pokefight.surge.sh/",
        "description":"A project showing off UI/UX and animation skills.",
        "imgurl":"images/portfolio/Pokefight_Thumb.jpg",
        "overtext":"Battle some Pokemon!"
      },
      {
        "name":"GitHub Repositories",
        "url":"https://github.com/Robert-DANT/",
        "description":"Have a look under the hood at works-in-progress and finished projects.",  
        "imgurl":"images/portfolio/Git_Repo_Thumb.jpg",
        "overtext":"Look at Git Repos"
      },
/*       {
        "name":"Video Work",
        "description":"Projects showing off animation and video editing skills.",
        "imgurl":"images/portfolio/Komunikate_Thumb_C.jpg"
      } */
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData