import React, { Component } from 'react';

let Portfolio = ( {resumeData} ) => {
/* export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData; */
    return (
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Past Projects and Work</h1>
          <div id="portfolio-wrapper" className="bgrid-thirds s-bgrid-thirds cf">
          {
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              return(
                <div className="columns portfolio-item">
                  {/* <div className="item-wrap"> Div class used to create magnific overlay on thumbnails */} 
                  <div>
                  <a class="popup-gallery" href={`${item.url}`} target="_blank" rel="noreferrer noopener">
                  {/* <a href="#modal-02"> */}
                  <div class="outer">
                      <img src={`${item.imgurl}`} className="item-img"/>
                      <div class="outeroverlay">
                          <p class="outertext">{item.overtext}</p>
                      </div>
                  </div>    
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
  </section>
        );
  }


export default Portfolio;